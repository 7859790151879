import React,{useState, useEffect} from 'react'
import { Link} from "gatsby"
import MainView from "../components/MainView/MainView"
//import MainViewMobile from "../components/MainView/MainView-mobile"

import Layout from "../components/layout"
import { useMediaQuery } from 'react-responsive';
import useWindowSize from '../hooks/useGatsbyWindowSize'



const IndexPage_1 = (props) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();

  //console.log( 'mobile :' + isMobile );



useEffect(() => {

  //console.log(mobile);
  setIsLoaded(true);

  /*
  let root = document.documentElement;
  root.style.setProperty('--drag-carousel-width', '100vw');
  */

},[mobile]);



if( mobile && isLoaded ){
return (

  <Layout>
    {/*<MainView items={1} mobile={true} width={width} height={height} location={props.location} />*/}
    <MainView slideWidth={100} slideHeight={60} dataSet={1} classList={'full'}prodPerView={'auto'} perView={2} mobile={true} width={width} bg={'dark'} curveTop={0} curveHeight={60} height={height} location={props.location}  />
    <div id="galleries-link">
    <Link className="link_underline cursor-scale grow-small" to="/decorative-art" >
    <span className="js-an-word">DECORATIVE ART</span>
    <span className="_underline"></span>
    </Link>
    &nbsp;
    <Link className="link_underline cursor-scale grow-small" to="/events" >
    <span className="js-an-word">EVENTS</span>
    <span className="_underline"></span>
    </Link>
    </div>
  </Layout>

);
}
if( !mobile && isLoaded){
  return (
    <Layout>
      <MainView slideWidth={35} slideHeight={70} dataSet={1} classList={'full'} prodPerView={2} perView={1} mobile={false} width={width} bg={'dark'} curveTop={-200} curveHeight={60} height={height} location={props.location}  />
      <div id="galleries-link">
      <Link className="selected" to="/decorative-art" >DECORATIVE ART</Link>&nbsp;<Link to="/events" >EVENTS</Link>
      </div>

      <div className="bottomHealine">
      <h3 className="fDisplay cursive">Made In<br/>Los Ángeles</h3>
      </div>
      <div className="bottomHealineRight">
      <h3 className="fDisplay cursive">Events</h3>
      </div>
    </Layout>

  );
}
}


export default IndexPage_1
